import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import HomeHeroSectionNew from "../components/sections/home-hero-section-new"
import PhilosophySection from "../components/sections/philosophy-section"
import CTASection from "../components/sections/cta-section"

const IndexPage = () => (
  <Layout>
    <Seo />
    <HomeHeroSectionNew />
    <PhilosophySection />
    <CTASection />
  </Layout>
)

export default IndexPage
