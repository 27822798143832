import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledDiv = styled.div`
  h1 {
    text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
  }

  h2 {
    text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
  }
`

const HomeHeroSectionNew = () => (
  <div style={{ display: "grid", background: "#423E42" }}>
    <StaticImage
      style={{
        gridArea: "1/1",
        objectFit: "cover",
        opacity: "0.6",
        height: 700
      }}
      width={2912}
      layout="constrained"
      alt="hero background"
      objectPosition="top center"
      src="../../images/backgrounds/shitcoinland.png"
      formats={["auto", "webp", "avif"]}
    />
    <div
      style={{
        gridArea: "1/1",
        position: "relative",
        placeItems: "center",
        display: "grid",
      }}
    >
      <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center justify-center mx-auto pt-24'>
        <StyledDiv className='w-11/12 flex flex-col items-center justify-center mx-auto'>

          <h1 className='text-4xl md:text-5xl font-bold text-white text-center mb-6'>
            <span className='leading-tight'>Shitecoin ($SHITE) Manifesto for Smoll Brain</span>
          </h1>

          <h2 className='w-full lg:w-10/12 lg:text-3xl text-white text-2xl font-medium text-center mb-6'>99% Fees Back to Stakers: You stake, you make.</h2>

          <h2 className='w-full lg:w-10/12 lg:text-3xl text-white text-2xl font-medium text-center'>Fair Launch: All in same boat, no sneaky sales.</h2>

          <div className='w-full flex flex-col items-center justify-center mt-12'>
            <a href="https://m3m3.meteora.ag/farms/Ueqhty98NSvQ8uy4yV5iCCFF6Jaoh81rpxd37QhgKf8" target="_blank" rel="noopener noreferrer">
              <button className="w-full bg-white border-2 border-white hover:border-site-yellow-hover text-2xl text-site-black font-display px-12 py-2 font-bold uppercase tracking-wider rounded-lg">
                Stake
              </button>
            </a>
          </div>
        </StyledDiv>
      </div>
    </div>
  </div>
)

export default HomeHeroSectionNew