import React from "react"

const CTASection = () => {
  return (
    <div className="bg-site-yellow w-full px-4 pt-24 pb-32">

      <div className="w-full max-w-screen-xl flex flex-col xl:flex-row items-center justify-center xl:justify-between mx-auto">
        <div className="w-full max-w-4xl">
          <h2 className="text-4xl md:text-6xl text-blue-900 text-center xl:text-left font-semibold mt-6">Join Us!</h2>
          <p className="text-lg xl:text-xl text-blue-900 text-center xl:text-left mt-6">Stake, meme, laugh. $SHITE - where we turn crap into cash.</p>
        </div>

        <div className="flex-shrink-0 mt-12 xl:mt-0">
          <a href="https://m3m3.meteora.ag/farms/Ueqhty98NSvQ8uy4yV5iCCFF6Jaoh81rpxd37QhgKf8" target="_blank" rel="noopener noreferrer">
            <button className="w-full bg-white border-2 border-white hover:border-site-yellow-hover text-2xl text-site-black font-display px-12 py-2 font-bold uppercase tracking-wider rounded-lg">
              Stake
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default CTASection