import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PhilosophySection = () => {
  return (
    <div id="philosophy" className="bg-gray-100 w-full px-4 pt-24 pb-32">

      <div className="w-full flex flex-col items-center justify-center max-w-lg mx-auto mb-6">
        <StaticImage
          src="../../images/logos/shitcoin-money.png"
          width="400"
          alt="Shitecoin Logo"
          className="rounded-xl"
        />
      </div>

      <div className="w-full max-w-2xl mx-auto">
        <h2 className="text-center text-3xl md:text-4xl font-bold uppercase">Philosophy</h2>
      </div>

      <div className="w-full max-w-screen-xl mx-auto mt-16">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 xl:gap-12">
          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <svg className="fill-current text-site-green block h-8 w-8 my-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/></svg>
              <h2 className="font-display font-semibold text-center text-2xl mb-4">See Through</h2>
              <p className="font-body text-gray-800 text-base text-center mb-4">
                Clear as mud, but we'll tell you everything.
              </p>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <StaticImage
                src="../../images/icons/family-svgrepo-com.png"
                width="65"
                alt="family icon"
                class="mt-2 mb-2"
              />
              <h2 className="font-display font-semibold text-center text-2xl mb-4">We Family</h2>
              <p className="font-body text-gray-800 text-base text-center mb-4">
                All about the meme life, together.
              </p>
            </div>
          </div>

          <div className="w-full rounded bg-white overflow-hidden shadow-lg">
            <div className="flex flex-col items-center p-6">
              <StaticImage
                src="../../images/icons/shit-svgrepo-com.png"
                width="60"
                alt="poop icon"
                class="mt-2 mb-1"
              />
              <h2 className="font-display font-semibold text-center text-2xl mb-4">Smart Poop</h2>
              <p className="font-body text-gray-800 text-base text-center mb-4">
                Best tech for shittiest coin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhilosophySection